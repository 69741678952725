import axios from "axios";
import { API_BASE_URL ,TOKEN} from ".";
export const token = localStorage.getItem('token');

console.log(token);

export const getCourses = (data) => {
    return axios.get(`${API_BASE_URL}/courses`,data,{});
};

export const getCourse = (id) => {
    return axios.post(`${API_BASE_URL}/admin/courses`, id);
};

export const createCourse = (data) => {
    const token = localStorage.getItem('token'); // Assurez-vous que le token est récupéré correctement
    if (!token) {
        console.error('Token is not available');
        return;
    }
    return axios.post(`${API_BASE_URL}/create/course`, data,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
};

export const getCourseAll = (data) => {
    return axios.get(`${API_BASE_URL}/courses`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        page: data.page,
        per_page: data.per_page,
        search: data.search
      }
    });
  }

  export const getMyCourse = (data) => {
    return axios.get(`${API_BASE_URL}/myCourses`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            page: data.page,
            per_page: data.per_page,
            search: data.search
        }
    });
};

  export const getCourseForUser = (data) => {
    return axios.get(`${API_BASE_URL}/all/courses`, {
      params: {
        page: data.page,
        per_page: data.per_page,
        search: data.search
      }
    });
  }

export const getAllCourseForAllUser = (data) => {
    return axios.get(`${API_BASE_URL}/courses/category`, {
      params: {
        page: data.page,
        per_page: data.per_page,
        search: data.search,
        category_id : data.category_id
      }
    });
}

export const updateCourse = (data) => {
    return axios.post(`${API_BASE_URL}/admin/courses`, data);
}

export const deleteCourse = (id) => {
    return axios.post(`${API_BASE_URL}/admin/courses`, id);
};

export const getCourseSections = (id) => {
    return axios.post(`${API_BASE_URL}/admin/course-sections`, id);
};

// get course by category

export const getCourseByCategory = (id) => {
    return axios.post(`${API_BASE_URL}/admin/courses`, id);
};


// get latest courses

export const getLastCourses = async () => {
       return await axios.get(`${API_BASE_URL}/latest-courses`);
};

// Récupérer les détails d'un cours par ID
export const getCourseDetail = (id) => {
    return axios.get(`${API_BASE_URL}/courses/detail/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => response.data)
    .catch(error => {
        console.error('Erreur lors de la récupération des détails du cours:', error);
        throw error;
    });
};