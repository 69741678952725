import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { FaBars, FaTimes, FaSignOutAlt } from "react-icons/fa";
import { useAuth } from "contexts/AuthProvider";

const SidebarContainer = styled.div`
  width: 200px;
  background-color: white;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  @media (max-width: 768px) {
    width: ${({ isOpen }) => (isOpen ? "200px" : "60px")};
    padding: ${({ isOpen }) => (isOpen ? "20px" : "10px")};
    transition: width 0.3s ease, padding 0.3s ease;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: ${({ isOpen }) => (isOpen ? "0" : "-200px")};
    z-index: 1000;
  }
`;

const Menu = styled.nav`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled(NavLink)`
  padding: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  margin: 5px 0;
  border-radius: 5px;

 

  &:hover {
    background-color: #e9ecef;
  }
`;

const MobileOnlyMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin: 5px 0;
  border-radius: 5px;
  text-decoration: none;
  color: #333;

  &:hover {
    background-color: #e9ecef;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const IconWrapper = styled.span`
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const ToggleButton = styled.button`
  display: none;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 10px;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1001;
    font-size: 24px;
  }
`;

const Sidebar = ({ menuItems }) => {
  const { user, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Fermer la barre latérale lorsque l'URL change
    setIsOpen(false);
  }, [location]);

  const handleToggle = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ToggleButton isOpen={isOpen} onClick={handleToggle}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </ToggleButton>
      <SidebarContainer ref={sidebarRef} isOpen={isOpen}>
        <Menu isOpen={isOpen}>
          {menuItems?.map((item, index) => (
            <MenuItem
              key={index}
              to={item.to}
              exact
              className={(item.path === location.pathname.split('/dashboard')[1] || location.pathname === item.path) ? "bg-[#e9ecef]" : ""}
            >
              <IconWrapper>{item.icon}</IconWrapper>
              <span>{item.label}</span>
            </MenuItem>
          ))}
          <MobileOnlyMenuItem onClick={logout}>
            <IconWrapper>
              <FaSignOutAlt />
            </IconWrapper>
            <span>Se déconnecter</span>
          </MobileOnlyMenuItem>
        </Menu>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
