import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaBook, FaFileInvoice, FaPlus } from "react-icons/fa";
import CourseCard from "./CourseCard";
import { useAuth } from "contexts/AuthProvider";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { updateCheckoutStatus } from "services/payment";
import { getLastCourses } from "services/course";

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const DashboardHeader = styled.header`
  margin-bottom: 20px;
`;

const UserGreeting = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #333;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background-color: #e0e0e0;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 10px;
  color: #333;
`;

const CoursesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    justify-content: center;
    gap: 10px;
  }

  @media (min-width: 769px) {
    justify-content: flex-start;
  }
`;

const ViewMoreButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #333;

  &:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

const MainContentDashboard = () => {
  const { user } = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get("status");
  const trabnsactionId = params.get("id");
  const close = params.get("close");
  const id = params.get("id");

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLastCourses()
      .then((response) => {
        console.log(response.data.data);
        setCourses(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleUpdateCheckoutStatus = async () => {
    try {
      return await updateCheckoutStatus(trabnsactionId, status);
    } catch (error) {
      console.error("Error updating checkout status:", error);
    }
  };

  useEffect(() => {
    if (status) {
      console.log(`Status: ${status}`);
      // Vous pouvez afficher une notification ou effectuer une action en fonction du statut
      if (status === "pending") {
        const response = handleUpdateCheckoutStatus(trabnsactionId, status);
        toast.info("Votre paiement est en attente.");
      } else if (status === "approved") {
        const response = handleUpdateCheckoutStatus(trabnsactionId, status);
        toast.success("Votre paiement a été réussi.");
      } else if (status === "failed") {
        const response = handleUpdateCheckoutStatus(trabnsactionId, status);
        toast.error("Votre paiement a échoué.");
      }
    }
  }, [location]);
  return (
    <>
      <ContentContainer>
        <DashboardHeader>
          <UserGreeting>Bonjour {user?.full_name}</UserGreeting>
          <ActionsContainer>
            <ActionButton>
              <FaBook /> Mes tutos
            </ActionButton>
            <ActionButton>
              <FaFileInvoice /> Mes factures
            </ActionButton>
          </ActionsContainer>
        </DashboardHeader>

        <Section>
          {(courses && courses.length > 0) ?? (
            <>
              <SectionTitle>Les Nouveautés</SectionTitle>
            </>
          ) }

          {courses && courses.length > 0 ? (
            <>
              <CoursesGrid>
                {courses.map((course) => (
                  <CourseCard
                    key={course.id}
                    course={course}
                    title={course.title}
                    price={course.price}
                    image={course.cover}
                    author={course.user.full_name}
                    devise={course.devise}
                  />
                ))}
              </CoursesGrid>
              <ViewMoreButton>Voir plus de nouveautés</ViewMoreButton>
            </>
          ) : (
            <p className="text-center text-gray-500">
              Pas de nouveau cours disponible.
            </p>
          )}
        </Section>

        {/* <Section>
          <SectionTitle>Les cours en promo</SectionTitle>
          <CoursesGrid>
            <div className="flex justify-center items-center shadow-sm">
              <div className="bg-red-500 text-white p-4 rounded-lg">
                <h2 className="text-lg font-bold">
                  Aucun cours en promo pour le moment
                </h2>
              </div>
            </div>
            {[...Array(3)].map((_, index) => (
              <CourseCard
                key={index}
                title="Fitness Development Strategy Buildup Laoreet"
                price="45.99"
                category=""
                author="Gustave Ali COUDI"
                rating="4.5"
                students="245"
              />
            ))}
          </CoursesGrid>
        </Section> */}
      </ContentContainer>
    </>
  );
};

export default MainContentDashboard;
