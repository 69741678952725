import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import ConfirmationPage from "./ConfirmationPage";
import { Elements } from "@stripe/react-stripe-js";
import favIcon from "../../assets/img/fav-orange.png";
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import { loadStripe } from "@stripe/stripe-js";

// Chargez votre clé publique Stripe
const stripePromise = await loadStripe(
  "pk_test_51QDYEIFD9AceWWzeEOUGhiuBC5MTSAB629vUak30DGxBTeCerC304C58xbQw6dMoKXgPKH6uBaLiRRWytUUBIn6X00vi3uFO6E"
);

// Image


const Checkout = () => {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="pages"
        secondParentMenu="shop"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      {/* CheckoutMain Start */}
      <Elements stripe={stripePromise}>
        <ConfirmationPage />
      </Elements>
      {/* CheckoutMain End */}

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default Checkout;
