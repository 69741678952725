import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import s3 from '../../../services/awsConfig'

const StepContainer = styled.div`
  padding: 2rem;
  width: 100%;

  @media (max-width: 768px) {
    padding: 1rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
  }
`;

const StepHeader = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1.25rem;

  @media (max-width: 480px) {
    font-size: 1.25rem;
  }
`;

const UploadContainer = styled.div`
  border: 2px dashed #ccc;
  border-radius: 1rem;
  padding: 10rem 2rem;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:hover {
    background: #f5f5f5;
  }

  @media (max-width: 768px) {
    padding: 6rem 1rem;
  }

  @media (max-width: 480px) {
    padding: 4rem 0.5rem;
  }
`;

const UploadInput = styled.input`
  display: none;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  border-radius: 1rem;
`;

const InstructionText = styled.p`
  margin-top: 1rem;
  font-size: 1rem;
  color: #666;

  @media (max-width: 480px) {
    font-size: 0.875rem;
  }
`;

const StepFive = ({ data, setData }) => {
  const [image, setImage] = useState(null);

  const uploadToS3 = (file) => {
    if (!(file instanceof Blob)) {
      file = new Blob([file]);
    }
    const fileNameWithoutSpaces = file.name.replace(/\s+/g, '-');

    const params = {
      Bucket: "akambi",
      Key: `${Date.now()}-${fileNameWithoutSpaces}`,
      Body: file,
    };

    return s3.upload(params).promise();
  };

  const handleFileUpload = async (file) => {
    try {
      const response = await uploadToS3(file);
      return response;
    } catch (error) {
      console.log('Erreur lors du téléchargement du fichier sur S3:', error);
      throw error;
    }
  };
  useEffect(() => {
    if (data.cover instanceof File) {
      setImage(URL.createObjectURL(data.cover));
    } else {
      setImage(null); // Reset image if data.cover is not valid
    }
  }, [data.cover]);

 const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      setImage(URL.createObjectURL(file));
      try {
        const response = await handleFileUpload(file);
        console.log(response)
        setData({
          ...data,
          cover: file,
          coverUrl: response.Location // Assurez-vous de mettre à jour avec l'URL de l'image téléchargée
        });
      } catch (error) {
        console.error('Erreur lors du téléchargement du fichier:', error);
      }
    }
  };

  return (
    <StepContainer>
      <StepHeader>Ajouter une image de couverture</StepHeader>
      <p>Phasellus enim magna, varius et commodo ut, ultricies vitae velit.</p>

      <UploadContainer onClick={() => document.getElementById('cover-upload').click()}>
        <UploadInput
          type="file"
          id="cover-upload"
          accept="image/*"
          onChange={handleImageUpload}
        />
        {image ? (
          <ImagePreview src={image} alt="Cover" />
        ) : (
          <InstructionText>
            <span role="img" aria-label="Upload">📷</span> Cliquer & glissez pour ajouter<br />
            ou <span style={{ color: '#b61c1c' }}>parcourir</span>
          </InstructionText>
        )}
      </UploadContainer>
    </StepContainer>
  );
};

export default StepFive;
