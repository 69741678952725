import React from 'react';
import styled from 'styled-components';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import PeopleIcon from '@mui/icons-material/People';
import image from 'assets/img/courses/home8/2.jpg';


const Card = styled.div`
  width: 250px;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: left;
  background-color: #fff;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PriceTag = styled.span`
  background-color: #AB1827;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  top: 20px;
  left: 20px;
`;

const CardContent = styled.div`
  position: relative;
`;

const CourseImage = styled.img`
  width: 100%;
  border-radius: 8px;
  height: 150px;
  object-fit: cover;
`;

const CourseTitle = styled.h3`
  font-size: 18px;
  color: #333;
  margin: 10px 0;
`;

const CourseCategory = styled.span`
  font-size: 14px;
  color: #333;
  font-weight: bold;
`;

const CourseAuthor = styled.p`
  font-size: 14px;
  color: #666;
  margin: 5px 0;
`;

const CourseFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

const CourseRating = styled.div`
  display: flex;
  align-items: center;
  
  svg {
    color: #E88B00;
  }

  span {
    margin-left: 5px;
    font-size: 14px;
    color: #666;
  }
`;

const CourseStudents = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    color: #333;
  }

  span {
    font-size: 14px;
    color: #333;
  }
`;

const CourseCard = ({ title, price, category, author, rating, students, image,devise }) => (
  <Card>
    <CardContent>
      <PriceTag>{price} {devise}</PriceTag>
      <CourseImage src={image} alt="Course" />
      <CourseTitle>{title}</CourseTitle>
      <CourseAuthor>{author}</CourseAuthor>
      <CourseFooter>
        <CourseCategory>{category}</CourseCategory>
        {/* <CourseStudents>
          <PeopleIcon />
          <span>{students}</span>
        </CourseStudents> */}
        {/* <CourseRating>
          {[...Array(4)].map((_, i) => (
            <StarIcon key={i} />
          ))}
          <StarHalfIcon />
          <span>{rating}</span>
        </CourseRating> */}
      </CourseFooter>
    </CardContent>
  </Card>
);

export default CourseCard;
